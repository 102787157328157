export default (() => {

  // タブ移動ループ
  $.fn.tabIndexLoop = function(options) {
    // 範囲を狭めたいときに指定
    let option = $.extend({
      'area' : 'body',
    }, options);
    // 現在のフォーカス項目用の変数
    let $focus = $();


    // 範囲の開閉トリガーが、範囲の中にあるか or 外にあるか
    let triggerOut = false;

    // 範囲の開閉トリガー（閉じるボタンなど）がループ領域の外にある場合、ループ対象に含める
    if (this.attr('data-trigger') === 'out') {
      triggerOut = true;
      $(':focus').addClass('tabloop-trigger');
    }

    // 領域の最初と最後の項目にマーキング
    const focusEl = this.find('a, button, input:enabled, select:enabled, textarea:enabled, [tabindex]:not([tabindex="-1"])');
    focusEl.eq(0).addClass('tabloop-first');
    focusEl.eq(-1).addClass('tabloop-last');

    return this.each(() => {
      $(option.area).on('keydown.tabindexLoop', event => {
        // フォーカス項目の格納
        $focus = $(':focus');

        // タブキー単独押下の場合
        if(pushKeyIsTab(event)) {
          if($focus.hasClass('tabloop-last')) {
            if(triggerOut) {
              toTrigger();
            } else {
              toFirst();
            }
          } else if($focus.hasClass('tabloop-trigger')) {
            toFirst();
          };
        };

        // shift + tab の場合
        if(pushKeyIsTabAndShift(event)){
          if($focus.hasClass('tabloop-first')) {
            if(triggerOut) {
              toTrigger();
            } else {
              toLast();
            }
          } else if($focus.hasClass('tabloop-trigger')) {
            toLast();
          };
        };
      });


      const pushKeyIsTab = event => {
        return event.keyCode === 9 && !event.shiftKey;
      };

      const pushKeyIsTabAndShift = event => {
        return event.keyCode === 9 && event.shiftKey;
      };

      const toFirst = () => {
        event.preventDefault();
        $('.tabloop-first', this).attr('tabindex','-1').focus();
      };

      const toLast = () => {
        event.preventDefault();
        $('.tabloop-last', this).attr('tabindex','-1').focus();
      };

      const toTrigger = () => {
        event.preventDefault();
        $('.tabloop-trigger').focus();
      };
    });
  };


})();
