// intersection observer
export default (() => {

  const target = [].slice.call(document.querySelectorAll('img.m-lazyimg'));
  const option = {
      root: null, // ルートとして指定するDOM
      rootMargin: '500px 0px',// 上下500px手前で発火
      threshold: 0 // ほんの少しでもrootに入ってきたら発火（1 なら完全に入ったら発火）
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target;
        const srcVal = img.dataset.src;
        // data-srcの値をsrcにセットする
        if (srcVal) {
          img.src = srcVal;
        }
        // 処理完了後、監視対象から外す
        observer.unobserve(img);
        // data-src属性の削除
        img.onload = function() {
          this.removeAttribute('data-src');
        }
      }
    });
  }, option);

  // Polyfill
  observer.POLL_INTERVAL = 100;
  // 監視を開始
  target.forEach((image) => {
    observer.observe(image)
  });


})();
