import isSp from './is_sp.js';

export default (() => {

  let scrT;
  let $hidden;
  let swiperGlCol2 = undefined;
  let swiperGlCol4 = undefined;
  let swiperGlCol5 = undefined;
  let swiperGlCol6 = undefined;
  let swiperGlCol8 = undefined;
  const goodslistCol2 = '.m-goodslist.swiper-container[data-col="2"]';
  const goodslistCol4 = '.m-goodslist.swiper-container[data-col="4"]';
  const goodslistCol5 = '.m-goodslist.swiper-container[data-col="5"]';
  const goodslistCol6 = '.m-goodslist.swiper-container[data-col="6"]';
  const goodslistCol8 = '.m-goodslist.swiper-container[data-col="8"]';

  // swiper option
  const optionGlCol2 = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 40,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true
  }
  const optionGlCol4 = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 40,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    on: {
      reachEnd: function () {
        this.$el.addClass('is-disable-after');
      },
    },
  }
  const optionGlCol5 = {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 30,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    on: {
      reachEnd: function () {
        this.$el.addClass('is-disable-after');
      },
    },
  }
  const optionGlCol6 = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 20,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      961: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 6,
        slidesPerGroup: 6,
      }
    },
    on: {
      reachEnd: function () {
        this.$el.addClass('is-disable-after');
      },
      // slideChange: function () {
      //   this.$el.removeClass('is-disable-after');
      // }
    },
  }
  const optionGlCol8 = {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 20,
    speed: 500,
    calculateHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: { enabled: true },
    slideToClickedSlide: true,
    resizeReInit: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      961: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 8,
        slidesPerGroup: 8,
      }
    }
  }

  $('.swiper-button-prev').click(function () {
    $(this).parents('.swiper-container').removeClass('is-disable-after');
  });

  // 商品リスト
  $.fn.goodslist = function () {
    $(this).each(function () {
      const _this = $(this);
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();

      $(window).on('load', function () {
        itemMore(_this);
        itemHeight(_this);
        priceWidth(_this);
      });

      $(window).on('resize', function () {
        // for SP devices
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }

        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function () {
          itemMore(_this);
          itemHeight(_this);
          priceWidth(_this);
        }, 100);
      });

      // 商品一覧の表示切り替え時
      $('.m-controlbar_view input').on('change', function () {
        itemHeight(_this);
        priceWidth(_this);
      });
      // タブ切り替え時
      // 商品詳細ページにおいて　ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
      $(document).on('click', '[role="tab"]', function (e) {
        itemHeight(_this);
        priceWidth(_this);
      });
    });
  }


  // ajax処理によるDOM切り替え後に呼び出すメソッド
  $.fn.initGoodslist = function () {
    $(this).each(function () {
      itemMore($(this));
      itemHeight($(this));
      priceWidth($(this));
    });
  }





  $.extend({
    // goodslist: col2
    goodslistCol2: function () {
      if (!$(goodslistCol2).length) return false;
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();
      swiperGlCol2 = undefined;
      initSwiperGlCol2();

      $(window).on('resize', function () {
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }
        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function () {
          initSwiperGlCol2();
        }, 500);
      });
    },

    // goodslist: col4
    goodslistCol4: function () {
      if (!$(goodslistCol4).length) return false;
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();
      swiperGlCol4 = undefined;
      initSwiperGlCol4();

      $(window).on('resize', function () {
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }
        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function () {
          initSwiperGlCol4();
        }, 500);
      });
    },

    // goodslist: col5
    goodslistCol5: function () {
      if (!$(goodslistCol5).length) return false;
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();
      swiperGlCol5 = undefined;
      initSwiperGlCol5();

      $(window).on('resize', function () {
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }
        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function () {
          initSwiperGlCol5();
        }, 500);
      });
    },

    // goodslist: col6 (toppage)
    goodslistCol6: function () {
      if (!$(goodslistCol6).length) return false;
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();
      const $gl = $(goodslistCol6);
      swiperGlCol6 = undefined;

      // カラム数変更
      if (currentW >= 1200) {
        $gl.attr('data-col', '6');
        initSwiperGlCol6();
      }
      else if (currentW < 1200 && currentW > 960) {
        $gl.attr('data-col', '5');
        initSwiperGlCol5();
      }
      else if (currentW < 961) {
        $gl.attr('data-col', '4');
        initSwiperGlCol4();
      }

      $(window).on('resize', function () {
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }

        // カラム数変更
        if (currentW >= 1200) {
          $gl.attr('data-col', '6');
          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function () {
            initSwiperGlCol6();
          }, 500);
        }
        else if (currentW < 1200 && currentW > 960) {
          $gl.attr('data-col', '5');
          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function () {
            initSwiperGlCol5();
          }, 500);
          //$gl.removeClass('is-disable-after');
        }
        else if (currentW < 961) {
          $gl.attr('data-col', '4');
          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function () {
            initSwiperGlCol4();
          }, 500);
          //$gl.removeClass('is-disable-after');
        }
      });
    },

    // goodslist: col8 (toppage: 画像のみ)
    goodslistCol8: function () {
      if (!$(goodslistCol8).length) return false;
      let timer = false;
      let currentW = window.innerWidth ? window.innerWidth : $(window).width();
      swiperGlCol8 = undefined;
      initSwiperGlCol8();

      $(window).on('resize', function () {
        let resizeW = window.innerWidth ? window.innerWidth : $(window).width();
        if (currentW === resizeW) { return; } else { currentW = resizeW; }
        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function () {
          initSwiperGlCol8();
        }, 500);
      });
    }
  });


  // swiper col2 init
  function initSwiperGlCol2() {
    const $gl = $(goodslistCol2);
    const $wrapper = $gl.find('.swiper-wrapper');
    const $slide = $gl.find('.swiper-slide');

    if (!isSp() && swiperGlCol2 === undefined) {
      swiperGlCol2 = new Swiper(goodslistCol2, optionGlCol2);
      swiperGlCol2 = $.makeArray(swiperGlCol2);
      swiperAdjustHeight(swiperGlCol2, $gl);
    }

    else if (isSp() && swiperGlCol2 !== undefined) {
      $.each(swiperGlCol2, function (i) {
        swiperGlCol2[i].destroy();
      });
      swiperGlCol2 = undefined;
      $wrapper.removeAttr('style');
      $slide.removeAttr('style');
      $gl.each(function () {
        itemMore($(this));
      });
    }

    if (!isSp()) {
      $gl.each(function () {
        itemHeight($(this));
      });
      $.each(swiperGlCol2, function (i) {
        swiperGlCol2[i].update();
        swiperGlCol2[i].navigation.update();
      });
    }
  }

  // swiper col4 init
  function initSwiperGlCol4() {
    const $gl = $(goodslistCol4);
    const $wrapper = $gl.find('.swiper-wrapper');
    const $slide = $gl.find('.swiper-slide');

    if (!isSp() && swiperGlCol4 === undefined) {
      swiperGlCol4 = new Swiper(goodslistCol4, optionGlCol4);
      swiperGlCol4 = $.makeArray(swiperGlCol4);
      swiperAdjustHeight(swiperGlCol4, $gl);
    }
    else if (isSp() && swiperGlCol4 !== undefined) {
      $.each(swiperGlCol4, function (i) {
        swiperGlCol4[i].destroy();
      });
      swiperGlCol4 = undefined;
      $wrapper.removeAttr('style');
      $slide.removeAttr('style');
      $gl.each(function () {
        itemMore($(this));
      });
    }

    if (!isSp()) {
      $gl.each(function () {
        itemHeight($(this));
      });
      $.each(swiperGlCol4, function (i) {
        swiperGlCol4[i].update();
        swiperGlCol4[i].navigation.update();
      });
    }
  }

  // swiper col5 init
  function initSwiperGlCol5() {
    const $gl = $(goodslistCol5);
    const $wrapper = $gl.find('.swiper-wrapper');
    const $slide = $gl.find('.swiper-slide');

    if (!isSp() && swiperGlCol5 === undefined) {
      swiperGlCol5 = new Swiper(goodslistCol5, optionGlCol5);
      swiperGlCol5 = $.makeArray(swiperGlCol5);
      swiperAdjustHeight(swiperGlCol5, $gl);
    }

    else if (isSp() && swiperGlCol5 !== undefined) {
      $.each(swiperGlCol5, function (i) {
        swiperGlCol5[i].destroy();
      });
      swiperGlCol5 = undefined;
      $wrapper.removeAttr('style');
      $slide.removeAttr('style');
      $gl.each(function () {
        itemMore($(this));
      });
    }

    if (!isSp()) {
      $gl.each(function () {
        itemHeight($(this));
      });
      $.each(swiperGlCol5, function (i) {
        swiperGlCol5[i].update();
        swiperGlCol5[i].navigation.update();
      });
    }
  }

  // swiper col6 init
  function initSwiperGlCol6() {
    const $gl = $(goodslistCol6);
    const $wrapper = $gl.find('.swiper-wrapper');
    const $slide = $gl.find('.swiper-slide');

    if (!isSp() && swiperGlCol6 === undefined) {
      swiperGlCol6 = new Swiper(goodslistCol6, optionGlCol6);
      swiperGlCol6 = $.makeArray(swiperGlCol6);
      swiperAdjustHeight(swiperGlCol6, $gl);
    }

    else if (isSp() && swiperGlCol6 !== undefined) {
      $.each(swiperGlCol6, function (i) {
        swiperGlCol6[i].destroy();
      });
      swiperGlCol6 = undefined;
      $wrapper.removeAttr('style');
      $slide.removeAttr('style');
      $gl.each(function () {
        itemMore($(this));
      });
    }

    if (!isSp()) {
      $gl.each(function () {
        itemHeight($(this));
      });
      $.each(swiperGlCol6, function (i) {
        swiperGlCol6[i].update();
        swiperGlCol6[i].navigation.update();
      });
    }
  }

  // swiper col8 init
  function initSwiperGlCol8() {
    const $gl = $(goodslistCol8);
    const $wrapper = $gl.find('.swiper-wrapper');
    const $slide = $gl.find('.swiper-slide');

    if (!isSp() && swiperGlCol8 === undefined) {
      swiperGlCol8 = new Swiper(goodslistCol8, optionGlCol8);
      swiperGlCol8 = $.makeArray(swiperGlCol8);
      swiperAdjustHeight(swiperGlCol8, $gl);
    }

    else if (isSp() && swiperGlCol8 !== undefined) {
      $.each(swiperGlCol8, function (i) {
        swiperGlCol8[i].destroy();
      });
      swiperGlCol8 = undefined;
      $wrapper.removeAttr('style');
      $slide.removeAttr('style');
      $gl.each(function () {
        itemMore($(this));
      });
    }

    if (!isSp()) {
      $gl.each(function () {
        itemHeight($(this));
      });
      $.each(swiperGlCol8, function (i) {
        swiperGlCol8[i].update();
        swiperGlCol8[i].navigation.update();
      });
    }
  }


  // 最後のスライドが端数の場合、カラーチップの有無による高さの違いを揃える
  function swiperAdjustHeight(swiper, $gl) {
    $.each(swiper, function (i) {
      const col = parseInt($gl.eq(i).attr('data-col'));
      const $item = $gl.eq(i).find('.m-goodslist_item');
      const $body = $item.find('.m-goodslist_body');
      const $color = $item.find('.m-goodslist_color');

      swiper[i].on('slideChange', function () {
        const lastIndex = (swiper[i].realIndex + col) - 1;
        const adjustIndex = (lastIndex - (col - 1));
        let colorH;
        // let bodyH;

        // スライド数に端数があり、最後のスライドの場合
        if ((swiper[i].slides.length % col !== 0) && swiper[i].isEnd) {

          // 最後のスライドのカラーエリア高さ揃え
          $color.eq(adjustIndex).css('height', 'auto');
          colorH = $color.eq(adjustIndex).height();

          for (let n = adjustIndex + 1; n < lastIndex; n++) {
            $color.eq(n).css('height', 'auto');
            if (colorH < $color.eq(n).height()) {
              colorH = $color.eq(n).height();
            }
          }
          for (let n = adjustIndex; n < lastIndex; n++) {
            $color.eq(n).css('height', colorH);
          }

          // 最後のスライドの m-goodslist_body 高さ揃え
          // $body.eq(adjustIndex).css('height', 'auto');
          // bodyH = $body.eq(adjustIndex).height();

          // for (let n = adjustIndex + 1; n < lastIndex; n++) {
          //   $body.eq(n).css('height', 'auto');
          //   if (bodyH < $body.eq(n).height()) {
          //     bodyH = $body.eq(n).height();
          //   }
          // }
          // for (let n = adjustIndex; n < lastIndex; n++) {
          //   $body.eq(n).css('height', bodyH);
          // }
        }

        // スライド数に余りがあり、最後以外のスライドの場合
        else if ((swiper[i].slides.length % col !== 0) && !swiper[i].isEnd) {
          itemHeight($gl.eq(i));
        }
      });
    });
  }



  $.goodslistCol2();
  $.goodslistCol4();
  $.goodslistCol5();
  $.goodslistCol6();
  $.goodslistCol8();
  $('.m-goodslist').goodslist();

  $(window).on('load', function () {
    $('.m-goodslist').initGoodslist();
  });





  // もっと見る開閉
  // ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
  // $(document).ready(function () {
  //   var $gl = $('.m-goodslist');
  //   var $listMore = $('.m-goodslist-more');
  //   var $allItem = $listMore.find('.m-goodslist_inner > .m-goodslist_item').length;
  //   $listMore.find('.m-goodslist_inner > .m-goodslist_item').hide();
  //   if (isSp()) {
  //     if ($gl.attr('data-more-sp')) {
  //       var num = $gl.attr('data-more-sp');
  //       $listMore.find('.m-goodslist_inner > .m-goodslist_item:lt(' + Number(num) + ')').show();
  //     }
  //   } else {
  //     if ($gl.attr('data-more-pc')) {
  //       var num = $gl.attr('data-more-pc');
  //       $listMore.find('.m-goodslist_inner > .m-goodslist_item:lt(' + Number(num) + ')').show();
  //     }
  //   }

  //   $(document).on('click', '.m-goodslist_more .m-btn', function (e) {
  //     const num = (Number(num) + Number(num) <= $allItem) ? Number(num) + Number(num) : $allItem;
  //     console.log(Number(num) + Number(num));
  //     $listMore.find('.m-goodslist_inner > .m-goodslist_item .m-goodslist_body').css({ height: 'auto' });
  //     $listMore.find('.m-goodslist_inner > .m-goodslist_item .m-goodslist_color').css({ height: 'auto' });
  //     $listMore.find('.m-goodslist_inner > li:lt(' + num + ')').show();
  //   });

  // });

  $(document).on('click', '.m-goodslist_more .m-btn', function (e) {
    const $gl = $(this).closest('.m-goodslist');
    const $textMore = $(this).find('.m-goodslist_textMore');
    const $textClose = $(this).find('.m-goodslist_textClose');
    $hidden = setHiddenArea($gl);

    const $allItem = $(this).parents('.m-goodslist-more').find('.m-goodslist_inner > li').length;
    const $showItem = $(this).parents('.m-goodslist-more').find('.m-goodslist_inner > li:visible').length;
    const $hideItem = $(this).parents('.m-goodslist-more').find('.m-goodslist_inner > li:hidden').length;

    console.log($allItem + '-' + $showItem + '-' + $hideItem);

    if (($showItem + $hideItem) === $allItem) {
      $(this).hide();
    }

    e.preventDefault();
    let status = $(this).attr('aria-expanded');
    if (status === 'false') {
      scrT = $(window).scrollTop();
      $(this).attr('aria-expanded', true);
      $hidden.fadeIn();
      itemHeight($gl);
      $textMore.hide();
      $textClose.show();
    } else {
      $(this).attr('aria-expanded', false);
      $hidden.fadeOut();
      $textMore.show();
      $textClose.hide();
      $(window).scrollTop(scrT);
    }
  });

  // もっと見る 非表示アイテム
  function setHiddenArea(gl) {
    const $gl = gl;
    // SP
    if (isSp()) {
      if ($gl.attr('data-more-sp')) {
        const num = $gl.attr('data-more-sp');
        if (num === 'all') {
          $hidden = 0;
        } else {
          $hidden = $gl.find('.m-goodslist_item:gt(' + (num - 1) + ')').hide();
        }
      }
      // 表示切り替えあり
      // else if ($gl.attr('data-view')) {
      // else if ($gl.attr('[data-view="normal"]') || $gl.attr('[data-view="list"]')) {
      else if ($gl.attr('data-view') === 'normal' || $gl.attr('data-view') === 'list' || $gl.hasClass('m-goodslist-favorite')) {
        $hidden = false;
      }
      else {
        $hidden = $gl.find('.m-goodslist_item:gt(1)');
      }
    }
    // PC
    else {
      if ($gl.attr('data-more-pc')) {
        const num = $gl.attr('data-more-pc');
        $hidden = $gl.find('.m-goodslist_item:gt(' + (num - 1) + ')');
      }
    }
    return $hidden;
  }



  // PC/SP の切り替え時の制御、もっと見るボタンの表示・非表示
  // デフォルトの表示件数以下だった場合は、もっと見るボタンを非表示にする
  function itemMore(_this) {
    const $more = _this.find('.m-goodslist_more');
    const $btn = $more.find('.m-btn');
    const $textMore = $btn.find('.m-goodslist_textMore');
    const $textClose = $btn.find('.m-goodslist_textClose');
    const $item = _this.find('.m-goodslist_item');
    const itemNum = $item.length;
    const numPc = _this.attr('data-more-pc');
    const numSp = _this.attr('data-more-sp');
    $hidden = setHiddenArea(_this);

    //    if (isSp()) {
    //      $btn.attr('aria-expanded', false);
    //      if ($hidden) $hidden.hide();
    //      $textMore.show();
    //      $textClose.hide();
    //      if (numSp) {
    //        if (itemNum <= numSp) {
    //          $more.hide();
    //        } else {
    //          $more.show();
    //        }
    //      }
    //    }
    //    else {
    //      if (_this.hasClass('m-goodslist-more')) {
    //        $btn.attr('aria-expanded', false);
    //        $item.show();
    //        if ($hidden) $hidden.hide();
    //        $textMore.show();
    //        $textClose.hide();
    //      } else {
    //        $item.show();
    //      }
    //      if (numPc) {
    //        if (itemNum <= numPc) {
    //          $more.hide();
    //        } else {
    //          $more.show();
    //        }
    //      }
    //    }

  }



  // 商品リストの高さ揃え
  function itemHeight(_this) {
    const col = parseInt(_this.attr('data-col'));
    const $item = _this.find('.m-goodslist_item');
    const $body = $item.find('.m-goodslist_body');
    const $color = $item.find('.m-goodslist_color');
    const $colorlist = $color.find('.m-goodsColor');

    $colorlist.each(function () {
      const colorMax = parseInt(_this.attr('data-colormax'));
      const $tile = $(this).find('li');
      let tileW;
      // カラータイルの表示数制御（for IE11）
      $tile.show();
      if (isSp()) {
        $(this).find('li:gt(3)').hide();
      }
      else if ($tile.length > colorMax) {
        $(this).find('li:gt(' + (colorMax - 1) + ')').hide();
      }

      // カラータイルの高さ設定
      //tileW = $tile.width();
      $tile.each(function() {
        tileW = $(this).width();
      })
      $(this).find('ul').css('height', tileW);
    });



    // カラーエリアと .m-goodslist_body の高さ揃え
    if (isSp() || _this.is('[data-view="sp"]') || _this.is('[data-view="list"]')) {
      $item.each(function () {
        const $img = $(this).find('.m-goodslist_img');
        const $color = $(this).find('.m-goodslist_color');
        const $tile = $color.find('.m-goodsColor li');
        $color.css('height', 'auto');
        // $body.css('height', 'auto');
        if ($tile.length) {
          let minH = $img.height() + $color.height() + 10;
          $(this).find('.m-goodslist_itemInner').css('min-height', minH);
        }
      });
    }
    else {
      // カラム行ごとのカラーエリア高さ揃え処理
      $color.css('height', 'auto');
      $color.each(function (i) {
        let colorH;

        if (i === 0 || i % col === 0) {
          colorH = $(this).height();

          for (let n = i + 1; n < i + col; n++) {
            if (colorH < $color.eq(n).height()) {
              colorH = $color.eq(n).height();
            }
          }

          for (let n = i; n < i + col; n++) {
            $color.eq(n).css('height', colorH);
          }
        }
      });

      // m-goodslist_body の高さ揃え
      // $body.css('height', 'auto');
      // $body.each(function (i) {
      //   let bodyH;

      //   if (i === 0 || i % col === 0) {
      //     bodyH = $(this).height();

      //     for (let n = i + 1; n < i + col; n++) {
      //       if (bodyH < $body.eq(n).height()) {
      //         bodyH = $body.eq(n).height();
      //       }
      //     }

      //     for (let n = i; n < i + col; n++) {
      //       $body.eq(n).css('height', bodyH);
      //     }
      //   }
      // });
    }
  }



  // リスト表示の場合、価格の横幅を揃える
  function priceWidth(_this) {
    const $prices = _this.find('.m-goodslist_price');

    if (_this.attr('data-view') === 'list') {
      let max = 0;
      $prices.css('width', 'auto');
      $prices.each(function () {
        if ($(this).width() > max) {
          max = $(this).width();
        }
      });
      $prices.width(max);
    }
    else {
      $prices.width('auto');
    }
  };



})();