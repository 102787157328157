import isSp from '../../../assets/js/main/is_sp.js';
import tabIndexLoop from '../../../assets/js/main/tabindex_loop.js';


export default (() => {

  // modal
  const $html = $('html');
  const _cls = 'is-modalOpened';
  let $trigger;


  $('.js-modal').on('click', function(e) {
    e.preventDefault();
    $trigger = $(this);
    const $modal = $('#' + $(this).attr('aria-controls'));
    $modal.modalCreate();
  });



  $.fn.extend({
    // モーダルを開く
    modalCreate: function() {
      const $modal = $(this);

      modalShow($modal);
      modalSizeAdjust($modal);
      // モーダル内のタブ移動ループ
      $modal.find('.m-modal_cnt').tabIndexLoop();

      $(window).on('resize', function() {
        modalSizeAdjust($modal);
      });
    },

    // モーダル閉じる
    modalClose: function($trigger) {
      const $modal = $(this);
      $html.removeClass(_cls);
      $('body').off('keydown.tabindexLoop');
      if ($trigger) $trigger.focus().blur();
      setTimeout( function() {
        $modal.scrollTop(0).attr('aria-hidden', 'true');
      }, 300);
    }
  });




  // モーダル表示
  function modalShow($modal) {
    $modal.attr('aria-hidden', 'false');
    setTimeout(function(){
      $html.addClass(_cls);
      $modal.focus();
    }, 100);
  }


  // モーダル上下のマージン設定
  function modalSizeAdjust($modal) {
    const marginSp = 40;
    const marginPc = 50;
    const $content = $modal.find('.m-modal_cnt');
    let height = window.innerHeight ? window.innerHeight : $(window).height();
    let contentHeight = $content.outerHeight();
    let margin = (height - contentHeight) / 2;

    if (!isSp()) {
      margin = margin < marginPc ? marginPc : margin;
      $content.css({'margin-top': margin, 'margin-bottom': margin});
    } else if (isSp() && $modal.hasClass('m-modal-select')) {
      margin = margin < marginSp ? marginSp : margin;
      $content.css({'margin-top': margin, 'margin-bottom': margin});
    } else {
      $content.css({'margin-top': 0, 'margin-bottom': 0});
    }
  }


  $(document).keydown(escKey).on('click', '.m-modal_close, .m-modal_closeBtn,  .m-modal_closeItem, .m-modal_bg', function(e) {
    e.preventDefault();
    const $modal = $(this).closest('.m-modal');
    $modal.modalClose($trigger);
  });

  // keybord events
  function escKey(e) {
    const $modal = $('.m-modal');
    if (e.keyCode === 27) {
      $modal.each(function() {
        if ($(this).attr('aria-hidden') === 'false') {
          $(this).modalClose($trigger);
        }
      });
    }
  }


})();
