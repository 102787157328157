import isSp from './is_sp.js';
import isTouch from './is_touchdevice.js';
import tabIndexLoop from '../../../assets/js/main/tabindex_loop.js';


export default (() => {

  const $trigger = $('.m-gnav_product > a');
  const $tgt = $('#' + $trigger.attr('aria-controls'));
  const $pnav = $tgt.find('.m-pnav_inner');
  const $items = $tgt.find('.m-pnav_item');
  const $bg = $('.m-pnav_bg');
  const $spmenu = $('.m-headerSp_menu');
  const $inner = $('.m-menuPanel_inner');
  const ACTIVE = 'is-active';
  let lv1hover, lv2hover; // setTimeout
  let sp = isSp();
  let touch = isTouch();


  if (!$trigger.length) {
    return false;
  }



  // 第1階層メニュー選択によって値を格納する要素
  const $lv2Title = $('.m-pnav_lv2 .m-pnav_menuTtl span');
  const $lv2Link  = $('.m-pnav_lv2 .m-pnav_menuTtl a');
  const $lv2Menu  = $('.m-pnav_lv2 .m-pnav_lv2_menu');
  const $lv3Title = $('.m-pnav_lv3 .m-pnav_menuTtl span');
  const $lv3Link  = $('.m-pnav_lv3 .m-pnav_menuTtl a');
  const $lv3Menu  = $('.m-pnav_lv3 .m-pnav_lv3_menu');
  const $detailImg = $('.m-pnav_detail .m-pnav_detailImg');
  const $detailTitle = $('.m-pnav_detail .m-pnav_detailTtl');
  const $detailDesc = $('.m-pnav_detail .m-pnav_detailDesc');



  // 第2、第3階層メニューを生成＆表示
  showLv2();
  showLv3();

  $(window).on('resize', function () {
    $('.m-wrapper').off('click.pnav');
    $('.m-pnav').off('click mouseenter mouseleave');

    // デバイス判定
    sp = isSp();
    touch = isTouch();

    showLv2();
    showLv3();

    // pnavが開いたままなら閉じる
    if ($trigger.attr('aria-expanded') === 'true') {
      pnavHide();
    }
  });



  // 商品カテゴリの開閉
  // -----------------------------
  $trigger.on('click', function(e) {
    e.preventDefault();
    if($(this).attr('aria-expanded') === 'false') {
      $trigger.attr('aria-expanded', 'true');
      $tgt.attr('aria-hidden', 'false');
      // gnav内のタブ移動ループ
      $tgt.tabIndexLoop();

      // SP
      if (sp) {
        $tgt.show();
        $spmenu.addClass('is-pnav-lv1');
        $spmenu.scrollTop(0);
        adjustHeight($('.m-pnav_lv1_inner'));
      }
      // PC
      else {
        $tgt.stop().slideDown(150);
        $bg.stop().fadeIn(150);
        // pnav以外がクリックされたら閉じる
        $('.m-wrapper').on('click.pnav', function(e) {
          if(!$(e.target).closest('.m-gnav_product').length && $trigger.attr('aria-expanded') === 'true') {
            pnavHide();
          }
        });
      }
    }
    else {
      pnavHide();
      // gnav内のタブ移動ループ解除
      $trigger.removeClass('tabloop-trigger');
      $('body').off('keydown.tabindexLoop');
    }
  });


  // SP： パネルを1つ戻る処理
  $('.m-pnav_back').on('click', function(e) {
    e.preventDefault();
    const lv = $(this).closest('.m-pnav_item').parent('[class^="m-pnav_lv"]').index();
    $spmenu.removeClass('is-pnav-lv' + (lv + 1));
    $('.m-pnav_menu li a').removeClass(ACTIVE);
    if (lv === 0) {
      pnavHide();
    } else {
      $spmenu.addClass('is-pnav-lv' + lv);
      adjustHeight($('.m-pnav_lv' + lv + '_inner'));
    }
  });


  // SP： ハンバーガーメニュークリック時
  $('.m-headerSp_btn').on('click', () => {
    if ($trigger.attr('aria-expanded') === 'true') pnavHide();
  });




  // 商品カテゴリを非表示
  // -----------------------------
  function pnavHide() {
    $trigger.attr('aria-expanded', 'false');
    $tgt.attr('aria-hidden', 'true').stop().slideUp(150);
    $bg.stop().fadeOut(150);
    $inner.height('auto').css('overflow', 'visible');
    $pnav.height('auto').removeClass('is-lv2 is-lv3');
    $items.height('auto');
    $('.m-pnav_menu li a').removeClass(ACTIVE);
    $spmenu.removeClass(function(index, className) {
      return (className.match(/\bis-pnav-lv\S+/g) || []).join(' ');
    });
  }



  // メニューの高さ調整
  // -----------------------------
  function adjustHeight(tgt) {
    if(sp) {
      $pnav.height(tgt.height());
      $inner.height(tgt.height()).css('overflow', 'scroll');
    }
    else {
      let max = 0;
      $items.css('height', 'auto');
      $items.each(function() {
        if ($(this).height() > max) {
          max = $(this).height();
        }
      });
      $items.height(max);
    }
  }



  // 第1階層を選択　→　第2階層を表示
  // -----------------------------
  function showLv2() {
    // SP or タッチデバイスPC
    if(sp || touch) {
      $('.m-pnav').on('click', '.m-pnav_lv1_menu > li > a', function(e) {
        e.preventDefault();
        const _this = $(this);
        createLv2(_this);
        $spmenu.scrollTop(0);
        $spmenu.removeClass('is-pnav-lv1').addClass('is-pnav-lv2');
      });
    }

    // PC
    else if (!sp && !touch) {
      $('.m-pnav').on('mouseenter', '.m-pnav_lv1_menu > li > a', function() {
        const _this = $(this);
        lv1hover = setTimeout(() => {
          createLv2(_this);
        }, 300);
      });

      $('.m-pnav').on('mouseleave', '.m-pnav_lv1_menu > li > a', function() {
        clearTimeout(lv1hover);
      });
      $('.m-pnav').on('mouseleave', function() {
        clearTimeout(lv1hover);
        $pnav.removeClass('is-lv2 is-lv3');
        $('.m-pnav_menu li a').removeClass(ACTIVE);
      });
    }
  }


  // 2階層メニューを生成
  // -----------------------------
  function createLv2(_this) {
    const lv1Idx = $('.m-pnav_lv1_menu > li > a').index(_this);
    const lv1Text = $(_this).text();
    const lv1Link = $(_this).attr('href');
    const lv1Desc = $(_this).siblings('.m-pnav_description').text();
    const $lv1Child = $(_this).siblings('.m-pnav_menu');
    const $lv1Img = $(_this).siblings('.m-pnav_img').find('img');

    $('.m-pnav_lv1_menu > li > a').removeClass(ACTIVE);
    $(_this).addClass(ACTIVE);

    // l2のメニューを設定
    $lv2Title.text(lv1Text);
    $lv2Link.attr('href', lv1Link);
    $lv2Menu.empty();
    $lv1Child.clone().appendTo($lv2Menu);
    // L1カテゴリのイメージ画像、タイトル、ディスクリプションを設定
    $detailImg.empty();
    $lv1Img.clone().appendTo($detailImg);
    $detailTitle.text(lv1Text);
    $detailDesc.text(lv1Desc);
    // メニュー領域の高さ調整
    adjustHeight($('.m-pnav_lv2_inner'));
    $('.m-pnav_lv2').attr('data-lv1', lv1Idx);
    $pnav.removeClass('is-lv3').addClass('is-lv2');
  }




  // 第2階層を選択　→　第3階層を表示
  // -----------------------------
  function showLv3() {
    // SP or タッチデバイスPC
    if(sp || touch) {
      $('.m-pnav').on('click', '.m-pnav_lv2_menu > .m-pnav_menu > li > a', function(e) {
        e.preventDefault();
        const _this = $(this);
        createLv3(_this);
        $spmenu.scrollTop(0);
        $spmenu.removeClass('is-pnav-lv2').addClass('is-pnav-lv3');
      });
    }

    // PC
    else if (!sp && !touch) {
      $('.m-pnav').on('mouseenter', '.m-pnav_lv2_menu > .m-pnav_menu > li > a', function() {
        const _this = $(this);
        lv2hover = setTimeout(() => {
          createLv3(_this);
        }, 300);
      });

      $('.m-pnav').on('mouseleave', '.m-pnav_lv2_menu > .m-pnav_menu > li > a', function() {
        clearTimeout(lv2hover);
      });
    }
  }


  // 第3階層メニューを生成
  // -----------------------------
  function createLv3(_this) {
    const lv2Text = $(_this).text();
    const lv2Link = $(_this).attr('href');
    const $lv2Child = $(_this).siblings('.m-pnav_menu');

    $('.m-pnav_lv2_menu > .m-pnav_menu > li > a').removeClass(ACTIVE);
    $(_this).addClass(ACTIVE);

    // l3のメニューを設定
    $lv3Title.text(lv2Text);
    $lv3Link.attr('href', lv2Link);
    $lv3Menu.html('');
    $lv2Child.clone().appendTo($lv3Menu);
    // メニュー領域の高さ調整
    adjustHeight($('.m-pnav_lv3_inner'));
    $pnav.removeClass('is-lv2').addClass('is-lv3');
  }
  // hide image mobile
  document.addEventListener("DOMContentLoaded", function() {
    var lazyloadImages = document.querySelectorAll("img.lazy");
    var lazyloadThrottleTimeout;

    function lazyload () {
    if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
    }
    lazyloadThrottleTimeout = setTimeout(function() {
        lazyloadImages.forEach(function(img) {
            if (window.screen.width > 767) {
                img.src = img.dataset.src;
                img.classList.remove('lazy');
                }
        });
        if(lazyloadImages.length == 0) {
            window.removeEventListener("load", lazyload);
            window.removeEventListener("resize", lazyload);

        }
    }, 10);
    }
    window.addEventListener("load", lazyload);
    window.addEventListener("resize", lazyload);
});

})();
