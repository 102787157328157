export default (() => {

  // パスワード表示
  $('.m-formPwShow').on('change', function() {
    const $tgt = $('.' + $(this).attr('data-tgt'));
    if ($(this).prop('checked')) {
      $tgt.attr('type', 'text');
    } else {
      $tgt.attr('type', 'password');
    }
  });

})();
