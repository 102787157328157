// window close
import isSp from './is_sp.js';
export default (() => {

  if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
    let posBf = $(window).scrollTop();
    $(window).scroll(function(){

      let posAf = $(this).scrollTop();

      if( posAf <= posBf){
          $('.m-headerArea').removeClass('is_scrollFixed');
      }else{
          $('.m-headerArea').addClass('is_scrollFixed');
      }
      posBf = posAf;
    });
  };
  if (isSp()) {
    let positionBf = $(window).scrollTop();
    let elementTop = $('.m-banner').outerHeight() || 0;
    let elementHeader = $('.m-header').outerHeight() || 0;
    let elementInput = $('.m-headerSp .m-search .m-input').outerHeight() + 32;
    $(window).scroll(function(){
      let positionAf = $(this).scrollTop();
      if( positionAf <= positionBf){
          $('.m-headerSp').removeClass('is-scroll');
          if( positionAf <= elementTop){
            $('.m-headerSp').removeClass('js-sticky');
          }
      }
      else{
        if(positionBf >= elementHeader - elementInput){
          $('.m-headerSp').addClass('js-sticky');
          $('.m-headerSp').addClass('is-scroll');
        }
      }
      positionBf = positionAf;
    });
  }

})();
