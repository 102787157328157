export default (() => {

  // breadcrumb for sp
  const $bread = $('.m-breadcrumb_inner');
  const $breadSp = $('.m-breadcrumbSp');
  if($bread.length) {
    $bread.clone().appendTo($breadSp);
  }

})();
