import isSp from './is_sp.js';
export default (() => {

  // goodslist view
  const $control = $('.m-controlbar_view input');
  const $tgt = $('.m-goodslist[data-view]');
  const $tgf = $('.m-goodslist-favorite[data-view]');

  if (isSp()) {
    $('input[name=view][value=normal]').prop("checked", false);
    $('input[name=view][value=list]').prop("checked", true);
    $tgt.attr('data-view', 'list');
    $tgf.attr('data-view', 'normal');
  }

  $control.on('change', function() {
    let val = $(this).val();
    $tgt.attr('data-view', val);
    $tgf.attr('data-view', val);
  });
  // $('.m-goodslist').goodslist();
  $('.m-controlbar_view input').click(function(){
    sessionStorage.clear();
    if($(this).parent().hasClass('m-controlbar_viewList')) {
      sessionStorage.clear();
      sessionStorage['my.checkbox_list'] = this.checked;
    } else {
      sessionStorage.clear();
      sessionStorage['my.checkbox_normal'] = this.checked;
    }
  });
  $( document ).ready(function() {
    if(sessionStorage['my.checkbox_list'] == 'true') {
      $('input[name=view][value=list]').click();
    }
    if(sessionStorage['my.checkbox_normal'] == 'true') {
      $('input[name=view][value=normal]').click();
    }
  })
  $(window).on('load', function(){
    if (isSp()) {
      if(sessionStorage['my.checkbox_normal'] == 'true') {
        $('input[name=view][value=list]').click();
        $('input[name=view][value=normal]').click();
      } else {
        $('input[name=view][value=list]').click();
      }
    } else {
      if(sessionStorage['my.checkbox_list'] == 'true') {
        $('input[name=view][value=normal]').click();
        $('input[name=view][value=list]').click();
      } else {
        $('input[name=view][value=normal]').click();
      }
    }
  });
  $('.m-goodslist_delete').each(function(){
    $(this).find('.m-btnArea a').click(function(){
      var _this = $(this);
      $('.m-addtoCard').removeClass('is-active');
      $(this).parent().prev('.m-addtoCard').stop().addClass('is-active');
      setTimeout(function(){
        _this.parent().prev('.m-addtoCard').removeClass('is-active');
      }, 5000);
    });
  });
})();
