import { setCookie, getCookie } from '../../../assets/js/main/cookie.js';

export default (() => {

  // follow area close
  const $close = $('.js-followClose');

  if (!$close.length) {
    return false;
  }

  // プロポイントへのアップグレードCVの場合
  if ($close.hasClass('m-propointCvFollow_close')) {
    const cookiePropoint = 'propoint_cv_hidden';

    const $follow = $close.closest('.js-followArea');

    // cookieがあったらCVエリアを非表示
    const cookieVal = getCookie(cookiePropoint);
    if (cookieVal) {
      $follow.addClass('g-hidden');
    }

    // CVエリアを閉じたら cookieをセット
    $close.on('click', function() {
      let date = new Date();
      date.setTime( date.getTime() + 1000 * 3600 * 24 ); // 24h
      date = date.toUTCString();
      setCookie(cookiePropoint, 'true', date);
      $follow.addClass('g-hidden');
    });
  }

})();
