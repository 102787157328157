export default (() => {

  // Align Hieght
  $(window).on('load resize', function () {
    $('.js-height').each(function() {
      const $items = $(this).find('.js-heightItem');
      if($(this).css('display') === 'block') {
        $items.css('height', 'auto');
      } else {
        boxHeight($items);
      }
    });

    function boxHeight(items) {
      const $items = items;
      let max = 0;
      $items.css('height', 'auto');
      $items.each(function() {
        if ($(this).height() > max) {
          max = $(this).height();
        }
      });
      $items.height(max);
    };
  });

})();
