export default (() => {

  // cart spinner
  let arySpinnerCtrl = [];
  let $target;
  let init;
  const spinSpeed = 150; // 変動スピード

  // 商品詳細ページにおいて　ajax処理によってDOM全体が置き換わるため、documentにイベントを設定
  $(document).on('touchstart mousedown click', '.m-itemNum_btn', function(e){
    if (arySpinnerCtrl['interval']) return false;
    arySpinnerCtrl['timestamp'] = e.timeStamp;
    const $btn = $(this);
    const cal = Number($btn.attr('data-cal'));
    $target = $btn.closest('.m-itemNum').find('.' + $btn.attr('data-target'));

    // touchstart mousedown
    if (e.type === 'touchstart' || e.type === 'mousedown') {
      // 初期値を取得
      init = Number($target.val());
      // change発火フラグをクリア
      arySpinnerCtrl['changeDone'] = false;
    }

    // クリック時
    if (e.type === 'click'){
      if (!arySpinnerCtrl['changeDone']) {
        spinnerCal($target, cal);
        if (init !== Number($target.val())) {
          $target.trigger('change');
        }
      }
      arySpinnerCtrl = [];
      return false;
    }

    // 長押し時
    setTimeout(function(){
      // インターバル未実行 + 長押しのイベントタイプスタンプ一致時に計算処理
      if (!arySpinnerCtrl['interval'] && arySpinnerCtrl['timestamp'] === e.timeStamp){
        arySpinnerCtrl['interval'] = setInterval( function(){ spinnerCal($target, cal) }, spinSpeed);
      }
    }, 500);
  });


  // 長押し解除時 画面スクロールも解除に含む
  $(document).on('touchend mouseup scroll', function(e){
    if (arySpinnerCtrl['interval']) {
      clearInterval(arySpinnerCtrl['interval']);
      arySpinnerCtrl = [];

      if (init !== Number($target.val())) {
        $target.trigger('change');
        // change発火フラグ
        arySpinnerCtrl['changeDone'] = true;
      }
    }
  });


  // 変動計算関数
  function spinnerCal(tgt, cal){
    const $target = tgt;
    const max = Number($target.attr('data-max'));
    const min = Number($target.attr('data-min'));
    const step = Math.abs(cal);
    let result = Number($target.val());
    result = result + cal;

    if (result > max) {
      $target.val(max);
    } else if (result < min) {
      $target.val(min);
    }

    // 手入力後の数値がステップ数の倍数にならない場合、入力値に近い倍数にする
    else if (((result - min) % step) !== 0) {
      // ＋ の場合
      if (result > init) {
        $target.val(result - ((result - min) % step));
      }
      // ー の場合
      else if (result < init) {
        $target.val(result + step - ((result - min) % step));
      }
    }
    else {
      $target.val(result);
    }
  }



})();
