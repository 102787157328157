export default (() => {

  if (/Android/i.test(navigator.userAgent)) {
    $('.m-flinkListApp .m-linkListS li a').attr("href", "https://play.google.com/store/apps/details?id=com.cainz.cainz&hl=ja");
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    $('.m-flinkListApp .m-linkListS li a').attr("href", "https://apps.apple.com/jp/app/%E3%82%AB%E3%82%A4%E3%83%B3%E3%82%BA/id966865570");
  } else {
    $('.m-flinkListApp .m-linkListS li a').attr("href", "https://www.cainz.com/service/cainz_app.html");
  }

})();
