// cookie
export function setCookie(cookieName, value, date) {
  const cookie = cookieName + '=' + value + ';';
  let expires;
  if (date) {
    expires = 'expires=' + date + ';';
  } else {
    expires = '';
  }
  document.cookie = cookie + expires;
}

export function getCookie(cookieName) {
  const cookieArr = document.cookie.split(';');
  let val = '';
  for (let i=0; i < cookieArr.length; i++){
    if (cookieArr[i].indexOf(cookieName) >= 0) {
      val = cookieArr[i].split('=');
      val = val[1];
      break;
    }
  }
  return val;
}
