export default (() => {

  //支払い方法、受け取り方法　フォームモーダル共通JS
   $(function(){
  	$('.js-delivery_postel').on('focusin',function(){
   			$(this).parents('.m-postalCodeInner').removeClass("on error");		
  	});
  	$('.js-delivery_postel').on('focusout',function(){
  		let posteltxt = $(this).val();
  		let postelCode = $(this).parents('.m-postalCode');
  		console.log(posteltxt.length)

  		if (posteltxt.match(/^\d+$/) && posteltxt.length >="7") {//正規表現で数字かつ7文字の時に発火
  			let Prefectures = "埼玉県"; //APIなどで入力数字から都道府県を取得して入れてください
   			let Municipalities = "本庄市"; //APIなどで入力数字から市区町村をを取得して入れてください
   			$(this).parents('.m-postalCodeInner').nextAll('.m-postalError').removeClass('on');
   			$(this).parents('.m-postalCodeInner').removeClass("error");
   			postelCode.nextAll('.js-delivery_postel-Prefectures').find('input').attr("placeholder",Prefectures );
   			postelCode.nextAll('.js-delivery_postel-Municipalities').find('input').attr("placeholder",Municipalities );
  		} else {
  			$(this).parents('.m-postalCodeInner').addClass("on error");
   			$(this).parents('.m-postalCodeInner').nextAll('.m-postalError').addClass('on');
  		}
  	});


  });

  $('.js-delivery_postel').on('input', function(){
  	check_numtype($(this));
  });

  var _chknum_value = "";     
  // 入力値の半角数字チェック
  function check_numtype(obj){

	// ２．変数の定義
	var txt_obj = $(obj).val();
	var text_length = txt_obj.length;

	// ３．入力した文字が半角数字かどうかチェック
	if(txt_obj.match(/^[0-9]+$/)){
	  // ３．１．文字数チェック
	  if(text_length > 7){
	       $(obj).val(_chknum_value);
	  }else{
	       _chknum_value = txt_obj;
	  }
	}else{
	  // ３．２．入力した文字が半角数字ではないとき
	  if(text_length == 0){
	       _chknum_value = "";
	  }else{
	       $(obj).val(_chknum_value);
	  }
	}
  }

　 //enterによるサブミット無効
　 $("input"). keydown(function(e) {
	 if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
	 	return false;
	 } else {
		return true;
	 }
 });

  //modal
  $('#btn').on('click',function(){
  //$('.m-deliveryAdress-checkbox + .m-deliveryAdress-data + .m-edit-btn label').on('click',function(){
    // 要素の位置を取得して変数に格納
    var isPosition = $('.m-deliveryAdress-create #point').position();
    console.log(isPosition.top);
    // タブクリックして箱が新しくなった時のスクロール位置を保存
	  var scrtop = $(window).scrollTop();
	  // 上記の上記のスクロール位置から箱の変化分を足す、かかる時間は0秒
	  $('#modalChangedelivery').css('overflow','visible').css('overflow','auto');
	  $(".m-deliveryAdress-create").animate(
	    { scrollTop: 0 },
	    { duration: 0 }
	  );
   });

})();
