import isSp from '../../../assets/js/main/is_sp.js';

export default (() => {

  // search suggest
  $.extend({
    // 検索サジェスト表示
    suggestShow: function() {
      if (isSp()) {
        $(window).scrollTop(0);
      }
      $('html').addClass('is-suggestShow');
      $('.m-suggest').attr('aria-hidden', 'false');
      $("body").addClass("body_fixed");
      $(".m-header").addClass("head_fixed");
    },

    // 検索サジェスト非表示
    suggestHide: function() {
      $('.m-suggest').attr('aria-hidden', 'true');
      $('html').removeClass('is-suggestShow');
      $("body").removeClass("body_fixed");
      $(".m-header").removeClass("head_fixed");

    }
  });


  $('.m-suggest_bg').on('click', function() {
    $.suggestHide();
  });

  // ios keyboard
  if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    $(".m-search .m-input input").focus(function() {
      $(this).closest(".m-search").addClass("ipt_focus");
    });
    $(".m-search .m-input input").focusout(function() {
      $(this).closest(".m-search").removeClass("ipt_focus");
    });
  }

  // search suggest
  function historyMaxHeight(ipt) {
    if($(window).width() > 1024) {
      var hist_mh = $(window).innerHeight() - $(ipt).closest(".m-search").find(".search-history__list").offset().top - 16;
      var sugs_mh = $(window).innerHeight() - $(ipt).closest(".m-search").find(".m-suggest").offset().top - 16;
      $(".search-history__list").css({
        'max-height': hist_mh + 'px',
        'overflow-y': 'auto'
      });

      $(".m-suggest").css({
        'max-height': sugs_mh + 'px',
        'overflow-y': 'auto'
      });
    }
  }

  $(window).on('resize', function (){
    historyMaxHeight($(".m-search .m-input"));
  });

  $(".m-search .m-input input").on("focus", function() {
    $(this).closest(".m-search").addClass("searchform_fixed");
    $(this).closest(".m-search").find(".search-close").addClass("show");
    if($(this).val() == '') {
      $(this).closest(".m-search").find(".search-history").addClass("show");
    }
    $("body").addClass("body_fixed");
    $(".m-header").addClass("head_fixed");
    historyMaxHeight($(this).closest(".m-input"));
  });
  $(".m-search .m-input textarea").on("focus", function() {
    $(this).closest(".m-search").addClass("searchform_fixed");
    $(this).closest(".m-search").find(".search-close").addClass("show");
    if($(this).val() == '') {
      $(this).closest(".m-search").find(".search-history").addClass("show");
    }
    $("body").addClass("body_fixed");
    $(".m-header").addClass("head_fixed");
    historyMaxHeight($(this).closest(".m-input"));
  });

  $(".m-search .m-input input").on("keypress keyup keydown", function() {
    if($(this).val() != '') {
      $(this).closest(".m-search").find(".search-history").removeClass("show");
      $(this).closest(".m-input").find(".search-cleartf").addClass("show");
      $(this).closest(".m-search").find(".m-suggest").attr('aria-hidden', 'false');
    } else {
      $(this).closest(".m-search").find(".search-history").addClass("show");
      $(this).closest(".m-input").find(".search-cleartf").removeClass("show");
      $(this).closest(".m-search").find(".m-suggest").attr('aria-hidden', 'true');
      // $(window).scrollTop(0);
    }
    $("body").addClass("body_fixed");
    $(".m-header").addClass("head_fixed");
    historyMaxHeight($(this).closest(".m-input"));
  });
  $(".m-search .m-input textarea").on("keypress keyup keydown", function() {
    if($(this).val() != '') {
      $(this).closest(".m-search").find(".search-history").removeClass("show");
      $(this).closest(".m-input").find(".search-cleartf").addClass("show");
      $(this).closest(".m-search").find(".m-suggest").attr('aria-hidden', 'false');
    } else {
      $(this).closest(".m-search").find(".search-history").addClass("show");
      $(this).closest(".m-input").find(".search-cleartf").removeClass("show");
      $(this).closest(".m-search").find(".m-suggest").attr('aria-hidden', 'true');
      // $(window).scrollTop(0);
    }
    $("body").addClass("body_fixed");
    $(".m-header").addClass("head_fixed");
    historyMaxHeight($(this).closest(".m-input"));
  });

  $(document).on('click', function() {
    $(".m-search .search-history").removeClass("show");
    $(".m-search .m-suggest").attr('aria-hidden', 'true');
  });
  $(".m-search").on("click", function (e){
    e.stopPropagation();
  });

  $(".m-search .m-input .search-cleartf").click(function() {
    $(this).closest(".m-input").find("input").val('');
    $(this).closest(".m-input").find("input").focus();
    $(this).closest(".m-input").find("textarea").val('');
    $(this).closest(".m-input").find("textarea").focus();
    $(this).closest(".m-search").find(".m-suggest").attr('aria-hidden', 'true');
    $(this).removeClass("show");
  });

  $(".m-search .search-close").click(function() {
    $(this).closest(".m-search").removeClass("searchform_fixed");
    $(this).closest(".m-search").find(".search-history").removeClass("show");
    $(this).closest(".m-search").find(".m-suggest").attr('aria-hidden', 'true');
    $(this).closest(".m-search").find(".m-input input").val('');
    $(this).closest(".m-search").find(".m-input textarea").val('');
    $(this).removeClass("show");
    $(this).closest(".m-search").find(".m-input .search-cleartf").removeClass("show");
    $("body").removeClass("body_fixed");
    $("html").removeClass("html_fixed");
    $(".m-header").removeClass("head_fixed");
  });

  $(".m-search .search-history .search-history__delall").click(function() {
    $(this).closest(".search-history").find(".search-history__list .search-history__item").remove();
    $(this).closest(".search-history").removeClass("have_search_history");
    $(this).closest(".search-history").addClass("no_search_history");
  });

  $(".m-search .search-history .search-history__list .search-history__item .m-iconClose").click(function() {
    $(this).closest(".search-history__item").remove();
  });

})();
