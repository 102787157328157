/* ▼ ここから： 表示確認用の記述のため、システム実装時には不要です ========================================= */
let deliveryBoxContent1 = `
  <div>店舗での受け取りは送料無料</div>
  <a class="txt_normal m-taR moduleIconfont" href=""><span class="moduleIconfont_icon"><span class="m-iconHelp g-pr5"></span></span>送料について</a>
`
let deliveryBoxContent2 = `
<div class="m-dilivery-noti">
  <p>基本送料のみ
    <span class="txt_normal">指定住所配送7,000円以上ご注文で基本送料無料</span>
  </p>
  <p>基本送料 + 個別送料1個あたりX,XXX円
    <span class="txt_normal">指定住所配送7,000円以上ご注文で基本送料無料</span>
  </p>
  <p>送料無料</p>
  <p>店舗での受け取りは送料無料</p>
</div>
<a class="txt_normal m-taR moduleIconfont" href=""><span class="moduleIconfont_icon"><span class="m-iconHelp g-pr5"></span></span>送料について</a>
`

$('.m-deliveryArea-detail, .m-detail-cart').each(function () {
  var $detailCart = '.m-detail-cart';
  var $txt = '.m-notification .m-notification__txt';
  $(this).find("input[name*='delivery']").click(function () {
    let id = $(this).attr('id') || $(this).attr('data-id');
    if (id.slice(-2) % 2 != 0) {
      if($(this).closest($detailCart).length){
        $(this).closest($detailCart).find($txt).html(deliveryBoxContent1).find('> div').attr('id', id).addClass('opt01')
      }else{
        $($txt).html(deliveryBoxContent1);
      }
    } else {
      if($(this).closest($detailCart).length){
        $(this).closest($detailCart).find($txt).html(deliveryBoxContent2).find('.m-dilivery-noti p').attr('id', id).addClass('opt02')
      }
      else{
        $($txt).html(deliveryBoxContent2);
      }
    }
  });

  if ($(this).find("input[id*='delivery02']").attr('checked')) {
    $($txt).html(deliveryBoxContent2);
  }
})

