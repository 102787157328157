import { auto } from "async";

export default (() => {

  // トップメインビジュアル
  const $topSwiper =$('.m-topMv .swiper-container');
  const optionTop = {
    loop: true,
    // loopedSlides: 3,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    centeredSlides: true,
    speed: 800,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: { el: '.swiper-pagination' },
  }

  let swiperTop = new Swiper($topSwiper, optionTop);


  // New top slider
  function topSPSlide() {
    let slidewrap_margin_sp = ($(window).width() - 798)/2;
    let slidewrap_coin_margin_pc = -($(window).width() - $(".m-main").width())/2;
    let slidewrap_margin_pc = ($(window).width() - 2048)/2;
    if($(window).width() <= 629) {
      $(".m-topslidewrap").css("margin-left", slidewrap_margin_sp);
    } else {
      $(".m-topslidecontainer").css("width", $(window).width());
      $(".m-topslidecontainer").css("margin-left", slidewrap_coin_margin_pc);
      $(".m-topslidewrap").css("margin-left", slidewrap_margin_pc);
    }
  }

  function topSlide() {
    topSPSlide();
    $(window).on('resize', function (){
      topSPSlide();
    });

    const $topSwiper_v2 = $('.m-topslidewrap .swiper-container_v2');
    const optionTop_v2 = {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 0,
      speed: 800,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        630: {
          slidesPerView: 7,
          spaceBetween: 24,
          centeredSlides: true,
        },
      },
    }

    let swiperTop_v2 = new Swiper($topSwiper_v2, optionTop_v2);
  }

  topSlide();

})();
