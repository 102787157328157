export default (() => {

  // add bookmark
  $(document).on('click', '.js-addBookmark', function (e) {
    e.preventDefault();
  });


  $.fn.addBookmark = function() {
    const ACTIVE = 'is-active';
    $('.js-addBookmark').toggleClass(ACTIVE);
  }

})();
