import { setCookie, getCookie } from '../../../assets/js/main/cookie.js';

export default (() => {

  // cookie check
  setCookie('check_cookie', 'true');
  const val = getCookie('check_cookie');

  if(!val) {
    $('.m-noCookie').removeClass('g-hidden');
  }

})();
