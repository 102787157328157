export default (() => {

  // メインビジュアル動画
  let currentW;
  let mvH;

  $('.m-mainVisual_movie').each(function() {
    const $video = $(this).find('.m-mainVisual_player');
    videoPos($video);
    $video.fadeIn(200);

    $(window).on('resize', function() {
      videoPos($video);
    });
  });


  // 動画をエリアの上下中央に表示
  function videoPos($video) {
    currentW = window.innerWidth ? window.innerWidth : $(window).width();
    mvH = (currentW <= 960) ? 320 : 400;

    let marginT = 0 - (((currentW * 0.5625) - mvH) / 2);
    $video.css('margin-top', marginT);
  };


  // IFrame Player APIの読み込み
  // const tag = document.createElement('script');
  // tag.src = "https://www.youtube.com/iframe_api";
  // const firstScriptTag = document.getElementsByTagName('script')[0];
  // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


})();
