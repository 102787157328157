import initValidation from './init_validation.js';
import initForm from './init_form.js';

export default (() => {


  // ラジオボタンによる要素の表示・非表示
  $('.js-expandControl_switch').on('change click', function() {
    let tgtArray = [];
    if ($(this).prop('tagName') === 'INPUT') {
      if ($(this).prop('checked')) {
        makeTgtArray($(this), tgtArray);
        switchDisp($(this), tgtArray);
      }
    } else {
      makeTgtArray($(this), tgtArray);
      switchDisp($(this), tgtArray);
    }
  });


  // 対象の配列を作成
  function makeTgtArray(_this, tgtArray) {
    const $parent = _this.closest('.js-expandControl');
    const $switch = $parent.find('.js-expandControl_switch');
    const $nestSwitch = $parent.find('.js-expandControl .js-expandControl_switch');
    let nestArray = [];

    $switch.each((i, el) => tgtArray.push($(el).attr('data-controls')));
    $nestSwitch.each((i, el) => nestArray.push($(el).attr('data-controls')));

    // ネストされたswitchの対象を除外する
    nestArray.forEach(function(nest) {
      $.each(tgtArray, function(i, item) {
        if (item === nest) {
          const index = i;
          tgtArray.splice(index, 1);
        }
      });
    });
  }


  // 切替えエリアの初期化、対象エリアの表示
  function switchDisp(_this, tgtArray) {
    const $tgt = $('.' + _this.attr('data-controls'));

    $.each(tgtArray, function(i, el) {
      const $el = $('.' + el);
      $el.addClass('g-hidden');

      // 表示切替えエリア内のバリデーションエラーをデフォルト状態に戻す
      const $validation = $el.find('.m-validation [aria-required="true"], .m-validation.is-checkRequired');
      $validation.each(function() {
        initValidation($(this));
      });

      // 入力したフォームの初期化
      const $formParts = $el.find('select, input, textarea');
      $formParts.each(function() {
        initForm($(this));
      });
    });

    $tgt.removeClass('g-hidden');
  }



  /*
    確認画面から「修正する」で戻った場合
    .js-expandControl_switch がチェックされていたら対応するエリアを表示
  */
  $(window).on('load', function() {
    let tgtArray = [];
    $('.js-expandControl_switch').each(function() {
      if ($(this).is(':checked')) {
        const $tgt = $('.' + $(this).attr('data-controls'));
        makeTgtArray($(this), tgtArray);

        $.each(tgtArray, function(i, el) {
          const $el = $('.' + el);
          $el.addClass('g-hidden');
        });
        $tgt.removeClass('g-hidden');
      }
    });
  });


})();
