// init validation error
export default ($tgt) => {

  // radio, checkbox
  if ($tgt.hasClass('is-checkRequired')) {
    $tgt.removeAttr('data-valid');
    $tgt.find(':radio, :checkbox').removeAttr('aria-invalid');
    $tgt.find('.m-formError').hide();
  }
  // select, input text, textarea
  else {
    const $msgs = $('#' + $tgt.attr('aria-describedby')).find('[data-error], .errorMsg');
    $tgt.closest('.m-validation').removeAttr('data-valid');
    $tgt.removeAttr('aria-invalid');
    $msgs.hide();
  }

};
