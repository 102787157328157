import isSp from './is_sp.js';

export default (() => {
  // SP時、CVエリアの店舗変更、住所変更リンクの位置設定
  $(window).on('load resize', function () {
    if (isSp()) {
      $('.m-productCvbox_delivery').each(function() {
        const $area = $(this).find('.m-productCvbox_date > dd');
        const $link = $(this).find('.m-productCvbox_change');
        let pos;

        pos = $area.offset().top - $(this).offset().top;
        $link.css({'bottom': 'auto', 'top': pos});
      });
    }
  });

})();
