// form validation
export default (() => {

  if(!$('.m-formValidation').length) {
    return false;
  }

  const $html = $('html');
  const $submit = $('.m-formValidation .m-formSubmit');
  const $agree = $('.m-formValidation .m-formAgree');
  let $required = $('.m-validation').find('[aria-required="true"]');
  let $noRequired = $('.m-validation').find('[aria-required="false"]');
  let $checkRequired = $('.m-validation.is-checkRequired');
  const $checkTgt = $('[data-validation]');
  const $pw = $('[data-pw]');
  const _trim = 'm-valTrim';

  $(window).on('load', function() {
    submitActivateCheck();
  });


  // 入力値を返す
  function valtrim($input) {
    if($input.hasClass(_trim)) {
      return $.trim($input.val());
    } else {
      return $input.val();
    }
  }


  // サブミットボタン活性化チェック
  function submitActivateCheck() {
    let nulls = [];
    let invalids = [];
    const $snsLogin = $('.m-boxIdlink_btns');
    const $snsBtns = $snsLogin.find('li > [class^="m-btn"]');

    $required = $('.m-validation').find('[aria-required="true"]').not(':disabled');
    $noRequired = $('.m-validation').find('[aria-required="false"]');
    $checkRequired = $('.m-validation.is-checkRequired');


    // 必須入力 ＋ パターンチェック
    $required.each((i, input) => {
      const checkVal = valtrim($(input));
      if(checkVal === '' && $(input).is(':visible')) {
        nulls.push($(input));
      } else if ($(input).attr('aria-invalid') === 'true') {
        invalids.push($(input));
      }
    });
    // パターンチェックのみ
    $noRequired.each((i, input) => {
      if ($(input).attr('aria-invalid') === 'true' && $(input).is(':visible')) {
        invalids.push($(input));
      }
    });
    // 必須チェック： ラジオボタン・チェックボックス
    $checkRequired.each((i, check) => {
      const $checked = $(check).find(':radio:checked, :checkbox:checked');
      if ($checked.length === 0 && $(check).is(':visible')) {
        nulls.push($(check));
      }
    });


    if (nulls.length === 0 && invalids.length === 0) {
      if ($agree.length) {
        if ($agree.is(':checked')) {
          $submit.removeAttr('disabled');
          snsLoginEnabled();
        } else {
          $submit.attr('disabled', 'disabled');
          snsLoginDisabled()
        }
      } else {
        $submit.removeAttr('disabled');
      }
    } else {
      $submit.attr('disabled', 'disabled');
    }

    // SNSログインボタンがある場合
    function snsLoginEnabled() {
      if ($snsLogin.length) {
        $snsBtns.each(function() {
          $(this).removeClass('is-disabled');
        });
      }
    }
    function snsLoginDisabled() {
      if ($snsLogin.length) {
        $snsBtns.each (function() {
          $(this).addClass('is-disabled');
        });
      }
    }
  }




  // invalid 処理
  function isInvalid($tgt, type) {
    const $msgs = $('#' + $tgt.attr('aria-describedby')).find('[data-error], .errorMsg');
    const $msg = $('#' + $tgt.attr('aria-describedby')).find('[data-error="' + type + '"]');
    $tgt.closest('.m-validation').attr('data-valid', false);
    $tgt.attr('aria-invalid', true);
    $msgs.hide();
    $msg.show();
  }

  // valid 処理
  function isValid($tgt, type) {
    const $msgs = $('#' + $tgt.attr('aria-describedby')).find('[data-error], .errorMsg');
    $tgt.closest('.m-validation').attr('data-valid', true);
    $tgt.removeAttr('aria-invalid');
    $msgs.hide();
  }

  // デフォルト状態に戻す
  function setDefault($tgt) {
    const $msgs = $('#' + $tgt.attr('aria-describedby')).find('[data-error], .errorMsg');
    $tgt.closest('.m-validation').removeAttr('data-valid');
    $tgt.removeAttr('aria-invalid');
    $msgs.hide();
  }


  // 同意チェック
  $('.m-formAgree').on('click', function() {
    submitActivateCheck();
  });


  // 必須入力チェック
  $required.on('blur', function() {
    const checkVal = valtrim($(this));
    if (checkVal === '') {
      isInvalid($(this), 'required');
    } else {
      isValid($(this), 'required');
    }
    submitActivateCheck();
  });


  // 必須じゃない　＋　パターンチェック
  $noRequired.on('blur', function() {
    const checkVal = valtrim($(this));
    if (checkVal === '') {
      setDefault($(this));
      submitActivateCheck();
    }
  });


  // 必須入力チェック（ラジオボタン・チェックボックス）
  // 次の項目にフォーカスした際にチェック
  $checkRequired.each(function() {
    const _this = $(this);
    const $nextInput = _this.closest('tr').nextAll('tr').find('input, textarea, select');

    $nextInput.on('focus', function() {
      radioValidation(_this);
    });

    _this.find(':radio, :checkbox').on('change', function() {
      radioValidation(_this);
    });

    function radioValidation($tgt) {
      const $msg = $tgt.find('.m-formError');
      const $checked = $tgt.find(':radio:checked, :checkbox:checked');
      if ($checked.length === 0) {
        isInvalid($tgt.find(':radio, :checkbox'), 'required');
        $msg.show();
      } else {
        isValid($tgt.find(':radio, :checkbox'), 'required');
        $msg.hide();
      }
    }
  });



  // パターンチェック
  $checkTgt.on('blur', function() {
    const checkVal = valtrim($(this));
    // nullの場合
    if (checkVal === '') { return false; }

    const $tgt = $(this);
    const val = checkVal;
    const validation = $tgt.attr('data-validation').split(' ');

    const number = /^\d+$/; // 半角数字のみ（必須）
    const numberAny = /^\d*$/; // 半角数字のみ（任意）
    const zipcode = /^\d{7}$/; // 半角数字7桁
    const birth = /^\d{6}$/; // 半角数字6桁
    const certification = /^\d{5}$/; // 半角数字5桁
    const number13 = /^\d{13}$/; // 半角数字13桁
    const order = /^\d{17}$/; // 半角数字17桁
    const tel = /^(\d{10,11})$/; // 半角数字10-11桁
    const cardNo = /^(\d{12})$|^(\d{16})$/; // 半角英字12桁または16桁
    const cardName = /^[a-zA-Z ]*$/; // 半角英字（半角スペースOK）
    const kana = /^[ァ-ヶー]+$/; // 全角カタカナのみ
    const name = /^[^\x01-\x7E\uFF65-\uFF9F]+$/; // 全角のみ
    const mail = /^[a-zA-Z0-9!#$%&'*\/=?^`{|}~_+-]+[a-zA-Z0-9.!#$%&'*\/=?^`{|}~_+-]*@([a-zA-Z0-9.-]*[a-zA-Z0-9])+\.[a-zA-Z]{2,}$/;
    const pw = /^\S{8,20}$/; // 空白文字（半角スペース、\t、\n、\r、\f）以外、8文字以上20文字以内
    const usepoint = /^[1-9]+[0-9]*0{2}$|^0$/; // 使用ポイント（100pt単位または0）

    let invalidCount = 0;

    $.each(validation, (i, check) => {
      if (check === 'number') {
        if(!number.test(val)) { invalidCount ++; }
      } else
      if (check === 'numberAny') {
        if(!numberAny.test(val)) { invalidCount ++; }
      } else
      if (check === 'zipcode') {
        if(!zipcode.test(val)) { invalidCount ++; }
      } else
      if (check === 'birth') {
        if(!birth.test(val)) { invalidCount ++; }
      } else
      if (check === 'certification') {
        if(!certification.test(val)) {invalidCount ++; }
      } else
      if (check === 'number13') {
        if(!number13.test(val)) {invalidCount ++; }
      } else
      if (check === 'order') {
        if(!order.test(val)) {invalidCount ++; }
      } else
      if (check === 'tel') {
        if(!tel.test(val)) {invalidCount ++; }
      } else
      if (check === 'cardNo') {
        if(!cardNo.test(val)) {invalidCount ++; }
      } else
      if (check === 'cardName') {
        if(!cardName.test(val)) {invalidCount ++; }
      } else
      if (check === 'kana') {
        if(!kana.test(val)) { invalidCount ++; }
      } else
      if (check === 'name') {
        if(!name.test(val)) { invalidCount ++; }
      } else
      if (check === 'mail') {
        if(!mail.test(val)) { invalidCount ++; }
      } else
      if (check === 'pw') {
        if(!pw.test(val)) { invalidCount ++; }
      } else
      if (check === 'usepoint') {
        if(!usepoint.test(val)) { invalidCount ++; }
      }
    });

    if(invalidCount === 0) {
      isValid($tgt, 'invalid');
    } else {
      isInvalid($tgt, 'invalid');
    }
    submitActivateCheck();
  });




  // ラジオボタン、セレクトが変更された時（要素が表示される場合あり）に submitActivateCheck を実行
  $(':radio, select, .js-linkedRequired_trigger').on('change', function() {
    setTimeout(function() {
      submitActivateCheck();
    }, 300);
  });
  // 表示領域の切替えが行われた時に submitActivateCheck を実行
  $('.js-registerArea_switch, .js-expandControl_switch, .js-dispControl').on('click', function() {
    setTimeout(function() {
      submitActivateCheck();
    }, 300);
  });



  // パスワード同一チェック
  $pw.on('blur', function() {
    const $pw1 = $('[data-pw="1"]');
    const $pw2 = $('[data-pw="2"]');
    const pw1 = valtrim($pw1);
    const pw2 = valtrim($pw2);
    if (pw1 !== '' && pw2 !== '') {
      if(pw1 !== pw2) {
        isInvalid($pw2, 'invalid');
      } else {
        isValid($pw2, 'invalid');
      }
    }
    submitActivateCheck();
  });

  //
  $(document).on('focus focusout','.js-Vallang.m-validation input',function(){
    $('.js-Vallang.m-validation input').blur(function() {
      if( !$(this).val() ) {
        $('.m-langErr').hide();
      }else {
        $('.m-langErr').show();
      }
    });
    let val = $(this).val();
    if(val.match(/^[A-Za-z]+ [A-Za-z]+$/)) {
      $('.js-Vallang .m-validation_checked').show();
      $('.js-Vallang').addClass('success');
    }else{
      $('.js-Vallang .m-validation_checked').hide();
      $('.js-Vallang').removeClass('success');
    }
  });

  $('.jsValidate').on('focus',function() {
    $('.jsValidate').removeClass('js-inputErr');
    $('.m-texttelErr_error').removeClass('js-error');
    $('.jsValidate').removeAttr('placeholder');
    //$('.m-textplaceholder').removeClass('js-redError');
    $('.m-textplaceholder').addClass('js-True');
    $(this).parents('.m-input_simulation').addClass('on');
  });
  $('.jsValidate').on('input focusout',function() {
    let text = $(this).val();
    let change = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){
      return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
    });
    text=text.replace(/\D/g,'');
    $(this).val(change);
    let len = $(this).val().length;
    if(len>2) text=text.replace(/.{2}/,'$&-')
    if(len>5) text=text.replace(/.{5}/,'$&-')
    let textCount = text.length;
    if(len != 0){
      if(textCount==12){
        $('.jsValidate').removeClass('js-inputErr');
        $('.m-texttelErr_error').removeClass('js-error');
        $('.m-textplaceholder').removeClass('js-redError');
        $('.m-textplaceholder').addClass('js-True');
        this.value=text;
      }else if(textCount < 12 && len < 12){
        $('.m-texttelErr_error').removeClass('js-error');
        $('.m-textplaceholder').addClass('js-redError');
        //$('.jsValidate').addClass('js-inputErr');
        //$('.m-textplaceholder').removeClass('js-True');
        $('.m-textplaceholder').addClass('js-True');
        $('.jsValidate').removeClass('js-inputErr');
        this.value=text;
        return;
      }else{
        $('.jsValidate').addClass('js-inputErr');
        $('.m-texttelErr_error').removeClass('js-error');
        //$('.m-textplaceholder').removeClass('js-redError');
        //$('.jsValidate').removeClass('js-inputErr');
        $('.m-textplaceholder').removeClass('js-True');
        this.value=text;
        return;
      }
    }else{
      $('.m-texttelErr_error').addClass('js-error');
      $('.jsValidate').addClass('js-inputErr');
      $('.m-textplaceholder').addClass('js-redError');
      $('.m-textplaceholder').removeClass('js-True');
      this.value=text;
      return;
    }
  });
  $('.jsValidate').on('focusout',function() {
    let text = $(this).val();
    let change = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){
      return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
    });
    text=text.replace(/\D/g,'');
    $(this).val(change);
    let len = $(this).val().length;
    if(len>2) text=text.replace(/.{2}/,'$&-')
    if(len>5) text=text.replace(/.{5}/,'$&-')
    let textCount = text.length;
    $('.m-textplaceholder').removeClass('js-True');

    if(len != 0){
      if(textCount < 12 && len < 12){
        $('.jsValidate').addClass('js-inputErr');
        $('.m-texttelErr_error').addClass('js-error');
      } else {
        $('.jsValidate').removeClass('js-inputErr');
        $('.m-texttelErr_error').removeClass('js-error');
      }
    }
  });
  // jsValidate area
  $('.jsValidate_area').on('focus',function() {
    $(this).parent().find('.jsValidate_area').removeClass('js-inputErr');
    $(this).parent().find('.m-texttelErr_error').removeClass('js-error');
    //$('.m-texttelErr_error02').removeClass('js-error');
    $(this).parent().find('.jsValidate_area').removeAttr('placeholder');
    $(this).parent().find('.m-textplaceholder').addClass('js-True');
    $(this).parents('.m-input_item').addClass('on');
  });
  $('.jsValidate_area').on('input focusout',function() {
    let text = $(this).val();
    let max = parseInt($(this).attr('max'));
    let min = parseInt($(this).attr('min'));
    let change = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){
      return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
    });
    text=text.replace(/\D/g,'');
    $(this).val(change);
    let len = $(this).val().length;
    let textCount = text.length;
    if(len != 0){
      if ($(this).val() > max)
        {
          $(this).parent().find('.m-textplaceholder').removeClass('js-True ');
          $(this).parent().find('.jsValidate_area').addClass('js-inputErr');
          $(this).parent().find('.m-texttelErr_error02').addClass('js-error');
          $(this).parent().find('.m-textplaceholder').addClass('js-redError');
          $(this).parent().find('.m-textMM').addClass('js-redError is-show');
          this.value=text;
          return;
        }
      else if ($(this).val() < min)
        {
          $(this).parent().find('.m-textplaceholder').removeClass('js-True ');
          $(this).parent().find('.jsValidate_area').addClass('js-inputErr');
          $(this).parent().find('.m-texttelErr_error02').addClass('js-error');
          $(this).parent().find('.m-textplaceholder').addClass('js-redError');
          $(this).parent().find('.m-textMM').addClass('js-redError is-show');
          this.value=text;
          return;
        }
      else{
          $(this).parent().find('.m-texttelErr_error').removeClass('js-error');
          $(this).parent().find('.m-texttelErr_error02').removeClass('js-error');
          $(this).parent().find('.jsValidate_area').removeClass('js-inputErr');
          $(this).parent().find('.m-textplaceholder').removeClass('js-redError');
          $(this).parent().find('.m-textplaceholder').removeClass('js-True');
          this.value=text;
          return;
      }
    }else{
        $(this).parent().find('.jsValidate_area').removeClass('js-inputErr');
        console.log('12121');
        $(this).parent().find('.m-texttelErr_error02').removeClass('js-error');
        $(this).parent().find('.m-texttelErr_error').removeClass('js-error');
        $(this).parent().find('.m-textplaceholder').removeClass('js-redError');
        $(this).parent().find('.m-textplaceholder').removeClass('js-True');
        this.value=text;
        return;
    }
  });
  //is run if user click out
  $('.jsValidate_area').on('focusout',function() {
    let text = $(this).val();
    let change = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){
      return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
    });
    let max = parseInt($(this).attr('max'));
    let min = parseInt($(this).attr('min'));
    text=text.replace(/\D/g,'');
    $(this).val(change);
    let len = $(this).val().length;
    let textCount = text.length;
    $('.m-textplaceholder').removeClass('js-True');
    if(len != 0){
      if ($(this).val() > max)
        {
          $(this).parent().find('.m-textplaceholder').removeClass('js-True ');
          $(this).parent().find('.jsValidate_area').addClass('js-inputErr');
         // $(this).parent().find('.m-texttelErr_error').addClass('js-error');
          $(this).parent().find('.m-texttelErr_error02').addClass('js-error');
          $(this).parent().find('.m-textplaceholder').addClass('js-redError');
          $(this).parent().find('.m-textMM').addClass('js-redError is-show');
          this.value=text;
          return;
        }
      else if ($(this).val() < min)
        {
          $(this).parent().find('.m-textplaceholder').removeClass('js-True');
          $(this).parent().find('.jsValidate_area').addClass('js-inputErr');
         // $(this).parent().find('.m-texttelErr_error').addClass('js-error');
          $(this).parent().find('.m-texttelErr_error02').addClass('js-error');
          $(this).parent().find('.m-textplaceholder').addClass('js-redError');
          $(this).parent().find('.m-textMM').addClass('js-redError is-show');
          this.value=text;
          return;
        }
      else{
          $(this).parent().find('.m-textplaceholder').removeClass('js-True');
          $(this).parent().find('.m-texttelErr_error').removeClass('js-error');
          $(this).parent().find('.m-texttelErr_error02').removeClass('js-error');
          $(this).parent().find('.jsValidate_area').removeClass('js-inputErr');
          this.value=text;
          return;
      }
    }else{
        $(this).parent().find('.jsValidate_area').addClass('js-inputErr');
        $(this).parent().find('.m-texttelErr_error').addClass('js-error');
        $(this).parent().find('.m-texttelErr_error02').removeClass('js-error');
        $(this).parent().find('.m-textMM').addClass('js-redError is-show');
        $(this).parent().find('.m-textplaceholder').addClass('js-redError');
        $(this).parent().find('.m-textplaceholder').removeClass('js-True');
        this.value=text;
        return;
    }
  });
})();
