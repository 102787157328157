// import isSp from './is_sp.js';

export default (() => {

  // 下階層メニューの表示制御
  // カレント表示（aria-current="page"）のメニュー階層のみ表示、その他の兄弟・親兄弟メニューは非表示
  const $sidenav = $('.m-sidenav');
  const $sidenavBtn = $('.m-sidenav_btn [aria-expanded]');
  const $sidenavCnt = $('.m-sidenav_cnt');
  const $sidenavClose = $('.m-sidenav_close');
  const $sidemenu = $sidenav.find('.m-sidemenu');
  const $current = $sidemenu.find('[aria-current="page"]');
  const $ul = $sidemenu.find($current.parents('ul'));
  const $child = $current.siblings('ul');
  const _parent = 'is-parent';
  const _child = 'is-child';
  const $showChild = $('.is-showChild');

  if (!$sidenav.length) {
    return false;
  }


  // SPローカルナビを閉じる
  // 開く処理は [aria-expanded] 共通
  $sidenavClose.on('click', function() {
    $('body').off('keydown.tabindexLoop');
    $sidenavCnt.hide();
    $sidenavCnt.attr('aria-hidden', 'true');
    $sidenavBtn.attr('aria-expanded', 'false');
    // 絞り込み検索ボタン
    $('.m-controlbar_refineSearch').attr('aria-expanded', 'false');
  });

  //SPサイドナビ表示処理
  $('.m-controlbar_refineSearch').on('click',()=>{
    $sidenavCnt.show();
  });


  // aria-current="page" がない場合はカテゴリトップのメニュー項目を表示する
  if (!$current.length) {
    $sidemenu.children('li').children('ul').hide();
  }

  // カレントの兄弟要素の下階層を非表示
  $current.closest('li').siblings('li').find('ul').hide();

  // カレントの親要素の兄弟要素を非表示
  $ul.each(function() {
    if ($(this).hasClass('m-sidemenu_lower')) {
      const $parent = $(this).closest('li');
      $parent.addClass(_parent).siblings('li').hide();
      $parent.closest('ul').css('padding', 0);
    }
  });

  // カレントに下階層があるなら子要素までを表示、孫要素は非表示
  if ($child.length) {
    $child.find('ul').hide();
    // 下階層メニューなら アイコン付きリンクにする
    if ($current.closest('ul').hasClass('m-sidemenu_lower')) {
      $child.addClass(_child);
    }
  }

  // カレントなしで子メニューを表示
  if ($showChild.length) {
    const $parent = $('.m-sidemenu').find($showChild.parents('li'));
    $parent.addClass(_parent).siblings('li').hide();
    $showChild.parents('ul').show().css('padding', 0);
    $showChild.siblings('li').hide();
  }


})();
