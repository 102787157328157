export default (() => {
  function updateBannerImage() {
    let banner = $('.m-banner_img');
    let element = $('.m-banner_img a');
    let count = element.width() / 375;
    let srcImage = banner.find('img').attr('src');
    let width = $(window).width();
    var str = '';

    if (width >= 768) {
      for (let i = 0; i < count; i++) {
        str += `<img src=${srcImage} alt="banner" >`;
      }
      element.html(str)
    }
  }

  $(window).on("load resize", function (e) {
    $('.m-banner_img a img:nth-child(n+2)').remove();
    updateBannerImage();
  });

})();