export default (() => {
  /**
   * Function set min height of .m-main in case body content too short
   */
  function setBodyMinHeight(){
    let headerHeight = $('.m-headerArea').outerHeight();
    let footerHeight = $('.m-footer').outerHeight();
    let windowHeight = $( window ).height();
    let mainBounding = Number($('.m-main').css('padding-top').replace('px',''))
                      +Number($('.m-main').css('padding-bottom').replace('px',''))
                      +Number($('.m-main').css('margin-top').replace('px',''))
                      +Number($('.m-main').css('margin-bottom').replace('px',''));
    let bodyHeight = windowHeight -(headerHeight + footerHeight+ mainBounding);
    $('.m-main').css({
      'min-height':bodyHeight+'px'
    })
  }
  // Run on load
  $(window).on('load',function(){
    setBodyMinHeight();
  })
  // Run on resize
  $(window).resize(function(){
    setBodyMinHeight();
  });
})();
