export default (() => {

  // SNS share
  $('.m-sns_btns').each(function() {
    const getUrl = location.href;
    const url = encodeURIComponent(getUrl);
    const getTitle = $("meta[property='og:title']").attr('content');
    const title = encodeURIComponent(getTitle);

    // twitter
    const twUrl = 'https://twitter.com/share?text=' + title + '&url=' + url;
    $(this).find('.m-sns_twitter').attr('href', twUrl);

    // facebook
    const fbUrl = 'http://www.facebook.com/share.php?u=' + url;
    $(this).find('.m-sns_facebook').attr('href', fbUrl);

    // line
    const lineUrl = 'https://social-plugins.line.me/lineit/share?url=' + url;
    $(this).find('.m-sns_line').attr('href', lineUrl);
  });

})();
