import isSp from './is_sp.js';

export default (() => {

  // もっと見る
  // 表示領域が指定の高さ以上の場合はもっと見るボタンを表示する。以下の場合は非表示にする。
  $.fn.moreHeight = function() {
    $(this).each(function() {
      const $more = $(this);
      const $tgt = $('#' + $more.attr('aria-controls'));
      const tgtH = $tgt.height();
      const dispH = parseInt($tgt.attr('data-height'));
      const dispHsp = parseInt($tgt.attr('data-heightSp'));
      const dataHeight = isSp() ? dispHsp : dispH;
      $more.css('display', tgtH <= dataHeight ? 'none': 'inline-block');
      $tgt.css('height', tgtH <= dataHeight ? 'auto' : dataHeight + 'px').attr('aria-hidden', tgtH <= dataHeight ? false:true);
    });
  }

  $('.js-moreHeight').moreHeight();

})();
