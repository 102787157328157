// search
export default (() => {

  const $search = $('.m-searchValidation');
  const $keyword = $search.find('input[type="text"]');
  const $submit = $search.closest('form').find('button');
  const $msgs = $('#' + $keyword.attr('aria-describedby')).find('[data-error]');
  let value;

  if ($search.length) {
    $keyword.on('keyup mouseup blur', function () {
      value = $keyword.val().trim();
      if (value.length > 1) {
        $keyword.attr('aria-invalid', false);
        $msgs.hide();
        $submit.prop('disabled', false);
      }
    });

    $keyword.on('blur', function () {
      value = $keyword.val().trim();
      const space = /^[\s　]+$/;
      if ((value !== '' && value.length < 2) || space.test($keyword.val())) {
        $keyword.attr('aria-invalid', true);
        $msgs.show();
        $submit.prop('disabled', true);
      }
      else if (value === '') {
        $keyword.attr('aria-invalid', false);
        $msgs.hide();
        $submit.prop('disabled', true);
      }
    });
  }

  $('.m-search input').keyup(function (event) {
    if (event.keyCode === 13) {
      $('button.m-search_btn').click();
    }
  });

  function init() {
    function onInput(evt) {
      let val = evt.target.value;
      const nlAt = val.indexOf('\n');
      if(nlAt >= 0) {
        val = val.replace(/\n/g, '');
        evt.target.value = val;
        $('button.m-search_btn').click();
      }
    }
    let e = document.getElementById('search');
    let e_sp = document.getElementById('search_sp');
    e && e.addEventListener('input', onInput);
    e_sp && e_sp.addEventListener('input', onInput);
  }
  window.addEventListener('DOMContentLoaded', init, false);



})();
