import isSp from './is_sp.js';

export default (() => {

  const $spmenu = $('.m-headerSp_menu');
  // const $panelMain = $('.m-menuPanel_main');
  const $inner = $('.m-menuPanel_inner');
  // const innerH = $inner.height();
  const $panel = $('.m-menuPanel');
  const $trigger = $('.m-menuPanelArea [aria-expanded][aria-controls]');
  const $back = $('.m-menuPanel_back');
  const SUB = 'is-subPanel';

  // サブパネルを閉じる処理
  function panelClose() {
    $spmenu.removeClass(SUB);
    $trigger.attr('aria-expanded', false);
    $panel.attr('aria-hidden', true);
    $inner.height('auto').css('overflow', 'visible');
  }


  $(window).on('load resize', function () {
    if (isSp()) {
      $trigger.off('click.pc');
      panelClose();

      $trigger.on('click.sp', function () {
        // パネル内のユーザ情報、店舗情報をクローンしてサブパネル内に表示
        const info = $(this).html();
        const $info = $(this).closest('.m-menuPanelArea').find('.m-menuPanel_info');
        const id = $(this).attr('aria-controls');
        const $panelSub = $('#' + id);
        const panelH = $panelSub.height();
        if ($info) $info.html(info);

        $spmenu.addClass(SUB);
        $inner.height(panelH).css('overflow', 'scroll');
      });

      // 戻るクリック時
      $back.on('click', function (e) {
        e.preventDefault();
        panelClose();
      });

      // ハンバーガーメニュークリック時
      $('.m-headerSp_btn').on('click', () => {
        panelClose();
      });


      // PC
    } else {
      $trigger.off('click.sp');

      $trigger.on('click.pc', function () {
        const id = $(this).attr('aria-controls');
        const $otherPanel = $panel.not('#' + id);
        $otherPanel.each(function () {
          const $thisTrigger = $(this).closest('.m-menuPanelArea').find('[aria-expanded][aria-controls]');
          $(this).attr('aria-hidden', true);
          $thisTrigger.attr('aria-expanded', false);
        });
      });

      // パネルエリア以外がクリックされたとき
      $('.m-wrapper').on('click', function (e) {
        if (!$(e.target).closest('.m-menuPanelArea').length) {
          $panel.attr('aria-hidden', true);
          $trigger.attr('aria-expanded', false);
        }
      });

      // SPハンバーガーメニューが開いていたら閉じる
      const $spBtn = $('.m-headerSp_btn');
      if ($spBtn.attr('aria-expanded') === 'true') {
        const label = $spBtn.attr('aria-label');
        const chgLabel = $spBtn.attr('data-chgLabel');
        const $triggerText = $spBtn.find('.m-triggerText');
        $spBtn.attr({ 'aria-expanded': 'false', 'aria-label': chgLabel, 'data-chgLabel': label });
        $spmenu.attr('aria-hidden', 'true');
        $triggerText.text(chgLabel);
        panelClose();
      }
    }
  });

})();
