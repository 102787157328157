export default (() => {

  // 住所検索ボタン・再入力ボタンの制御
  $('.m-addGroup').each(function() {
    const $btnSearch = $(this).find('.js-addSearch');
    const $btnReEnter = $(this).find('.js-reEnter');
    const $zipcode = $(this).find('.m-addGroup_zipcode');
    const $autoInput = $(this).find('.m-addGroup_auto, .m-addGroup_address');
    const $prefecture = $(this).find('.m-addGroup_prefecture');

    // 住所検索ボタン・再入力ボタンの活性・非活性化
    $(window).on('load', function() {
      if ($zipcode.length && !$zipcode.val().match(/\d{7}/)) {
        $btnSearch.attr('disabled', 'disabled');
        $btnReEnter.addClass('g-hidden');
      } else if ($zipcode.length && $zipcode.val().match(/\d{7}/) && $prefecture.val() !== '') {
        $zipcode.attr('readonly', 'readonly');
        $btnSearch.addClass('g-hidden');
        $btnReEnter.removeClass('g-hidden');
      }
    });



    // 郵便番号バリデーションチェックOKな場合： 住所検索ボタン活性化
    $zipcode.on('keyup blur', function() {
      if ($zipcode.val().match(/\d{7}/)) {
        $btnSearch.removeAttr('disabled');
      } else {
        $btnSearch.attr('disabled', 'disabled');
      }
    });


    // 再入力ボタン
    $btnReEnter.on('click', function() {
      // ボタンの表示切り替え
      $btnSearch.removeClass('g-hidden').attr('disabled', 'disabled');
      $(this).addClass('g-hidden');
      $zipcode.closest('.m-validation').removeAttr('data-valid');
      // 自動インプット初期化
      $autoInput.each(function() {
        $(this).val('').closest('.m-validation').removeAttr('data-valid');
      });
      // サブミットボタン活性化チェック
      $prefecture.focus().blur();
      // 郵便番号初期化
      $zipcode.removeAttr('readonly').val('').focus();
    });

  });

})();
