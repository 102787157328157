// anchor scroll
export default (() => {

  $(document).on('click', 'a[href^="#"]:not("[role=tab]")', function(e) {
    e.preventDefault();
    const href = $(this).attr('href');
    const target = $(href === "#" || href === "" ? 'html' : href);

    if(target.length) {
      let position = target.offset().top;
      if ($('.m-pointAncFollow').length) {
        position = position - $('.m-pointAncFollow').height();
      }

      $('html, body').animate({ scrollTop: position }, {
        duration: 400,
        step: function (now, fx) {
          let newOffset = target.offset().top;
          if (fx.end !== newOffset) {
            fx.end = newOffset
          }
        },
      });
      target.focus();

      if (target.is(':focus')){ // Checking if the target was focused
        return false;
      } else {
        target.attr('tabindex','-1').css('outline', 'none').focus(); // Adding tabindex for elements not focusable
      };
    }
  });


  // ページ外からのアンカーリンク
  $(window).on('load', function(e) {
    const hash = location.hash;
    if($(hash).length) {
      e.preventDefault();
      const position = $(hash).offset().top;
      $('html, body').animate({ scrollTop: position }, 400, 'swing');
    }
  });

})();
