import initValidation from './init_validation.js';
import initForm from './init_form.js';

export default (() => {

  const $area = $('.m-registerArea');
  const $check = $area.find('.js-registerArea_check');

  if ($area) {

    // 会員情報登録ページ： 一般・プロポイント会員の切り替え
    $('.js-registerArea_switch').on('click', function(e) {
      e.preventDefault();
      const areaType = $area.attr('data-type');
      const $hiddenArea = $('.m-registerArea_' + areaType);

      initHiddenArea($hiddenArea);

      // data属性切り替え
      $area.attr('data-type', $(this).attr('data-value'));
    });


    // 会員情報変更ページ： プロポイント会員として登録チェック
    $check.on('change', function() {
      if ($(this).prop('checked')) {
        $('#registerPropoint').modalCreate();
      } else {
        initHiddenArea($('.m-registerArea_propoint'));
        $area.attr('data-type', 'general');
      }
    });

    // 会員情報変更ページ： 既存プロ会員の場合（初回時のみ）：「職業」「カード区分」のみを表示
    if ($('.m-registerArea_propoint #propoint-pro').prop('checked')) {
      $area.attr('data-type', 'propoint');
      $('.js-registerArea_check').closest('tr').addClass('g-hidden');
    }

    // プロポイント会員登録のモーダルを閉じた場合は、チェックを外す
    $('#registerPropoint').find('.m-modal_closeBtn, .m-modal_close, .m-modal_bg').on('click', function() {
      $check.prop('checked', false);
    });



    // 入力したフォーム要素、バリデーション項目の初期化
    function initHiddenArea($hiddenArea) {
      const $validation = $hiddenArea.find('.m-validation [aria-required="true"], .m-validation.is-checkRequired');
      $validation.each(function() {
        initValidation($(this));
      });

      const $formParts = $hiddenArea.find('select, input, textarea');
      $formParts.each(function() {
        initForm($(this));
        if ($(this).hasClass('js-expandControl_switch')) {
          const $hiddenTgt = $('.' + $(this).attr('data-controls'));
          $hiddenTgt.addClass('g-hidden');
        }
      });
    }




    /*
      確認画面から「修正する」で戻った場合
      プロポイント会員用の必須項目に値が入っていたらプロポイントエリアを表示
    */
    $(window).on('load', function() {
      const $proCheck = $('.js-registerArea_check');
      const $proRequired = $('.m-registerArea_propoint').find('[aria-required="true"], .is-checkRequired input:radio:checked');
      $proRequired.each(function() {
        if ($(this).val()) {
          $area.attr('data-type', 'propoint');
          $proCheck.prop('checked', true);
        }
      });

      // プロポイント会員として登録チェックがついていたらプロポイントエリアを表示
      if ($proCheck.prop('checked')) {
        $area.attr('data-type', 'propoint');
      }
    });

  }

})();
